import { Action, createReducer, on, State } from '@ngrx/store';
import { initialProfile,ProfileState } from './profile.state';
 
import * as ProfileActions from './profiles.actions';

        const global = localStorage.getItem('global');

        let initData = {
                
                profile : {
                        image: "" ,
                        fname :"" ,
                        lname :"" ,
                        email :"" ,
                        phone :"" ,
                }
        }

        if( global){
                initData = JSON.parse(global)
        }


const profileReducer = createReducer(

        //initialState,
        initData,

        on(ProfileActions.setProfile, (state,profile) => {
                return {
                   ...state,
                   profile: profile
                };
        }),

        on(ProfileActions.resetProfile, (state,profile) => {
                return {
                        ...state,
                        profile:  profile
                       
                };
        }),

  );

    export function reducer(state: ProfileState | undefined, action: Action) {
                return profileReducer(state, action);
    }