import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class AlertService {


  /* ----------------------- sweetalert2 --------------------- */

  constructor( private router: Router ) { }


/* --------------------------------------------------------------- */

  async deleteConfirm() { 

      let confirm  = await Swal.fire({
          title: 'Confirmation',
          text: 'Confirmez-vous la suppression ?',
          icon: 'question',
          iconColor:'#ff3366',
          showCancelButton: true,
          confirmButtonText: 'Oui, supprimez-le',
          cancelButtonText: 'Non, annuler',
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
      }).then((result :any) => {
          return result ;
      })
      return confirm.isConfirmed ;
  } 
/* --------------------------------------------------------------- */

async confirm() { 

  let confirm  = await Swal.fire({
      title: 'Confirmation',
      text: 'Confirmez-vous?',
      icon: 'question',
      iconColor:'#ff3366',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
  }).then((result :any) => {
      return result ;
  })
  return confirm.isConfirmed ;
} 
/* ------------------------------------------------------ */

  async alertSuccess(messsage :string) { 

    let confirm  = await Swal.fire({ 
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          title: messsage,
          icon: 'success'}).then((result :any) => {
            return result ;
    })
    return confirm ;
  } 

  /* ------------------------------------------------------ */

  async alertSuccess2(messsage :string) { 

    let confirm  = await Swal.fire({ 

              title: messsage,
              icon: 'success',
              iconColor:'#6571ff',
              confirmButtonColor:'#6571ff',
              confirmButtonText: 'OK, je comprends',
              allowOutsideClick: false,
              allowEscapeKey: false,
              allowEnterKey: false,
              
           
        }).then((result :any) => {
            return result ;
    })
    return confirm ;
  } 

/* ------------------------------------------------------ */

async alertError(messsage :string) { 

  let confirm  = await Swal.fire({ 
          title: messsage,
          icon: 'warning',
          iconColor:'#ff3366',
          confirmButtonColor:'#ff3366',
          confirmButtonText: 'OK, je comprends',
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
      }).then((result :any) => {
            return result ;
           })
  return confirm ;
} 






}
