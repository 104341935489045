<nav class="navbar">

  <a href="" class="sidebar-toggler" (click)="toggleSidebar($event)">
    <i class="feather icon-menu"></i>
  </a>

  <div class="navbar-content">

    <ul class="navbar-nav">
     <!--  <li class="nav-item nav-notifications" ngbDropdown>
        <a class="nav-link" ngbDropdownToggle id="notificationDropdown">
          <i class="link-icon feather icon-bell"></i>
          <div class="indicator">
            <div class="circles"></div>
          </div>
        </a>
        <div ngbDropdownMenu class="px-0" aria-labelledby="notificationDropdown">
          <div class="px-3 py-2 d-flex align-items-center justify-content-between border-bottom">
            <p>6 Notifications</p>
            <a href="" (click)="false" class="text-muted">Tout effacer </a>
          </div>
          <div class="p-1">
            <a href="" (click)="false" class="dropdown-item d-flex align-items-center py-2">
              <div class="wd-30 ht-30 d-flex align-items-center justify-content-center bg-primary rounded-circle me-3">
                <i class="feather icon-gift icon-sm text-white"></i>
              </div>
              <div class="flex-grow-1 me-2">
                <p>New Order Recieved</p>
                <p class="tx-12 text-muted">30 min ago</p>
              </div>	
            </a>
            <a href="" (click)="false" class="dropdown-item d-flex align-items-center py-2">
              <div class="wd-30 ht-30 d-flex align-items-center justify-content-center bg-primary rounded-circle me-3">
                <i class="feather icon-alert-circle icon-sm text-white"></i>
              </div>
              <div class="flex-grow-1 me-2">
                <p>Server Limit Reached!</p>
                <p class="tx-12 text-muted">1 hrs ago</p>
              </div>	
            </a>
             
          </div>
          <div class="px-3 py-2 d-flex align-items-center justify-content-center border-top">
            <a href="" (click)="false">View all</a>
          </div>
        </div>
      </li> -->
      <li class="nav-item nav-profile" ngbDropdown>
        <a class="nav-link" ngbDropdownToggle id="profileDropdown">
          <img class="wd-30 ht-30 rounded-circle" [src]="image_base_url+user.image" alt="profile">
        </a>
        <div ngbDropdownMenu class="px-0" aria-labelledby="profileDropdown">
          <div class="d-flex flex-column align-items-center border-bottom px-5 py-3">
            <div class="mb-3">
              <img class="wd-80 ht-80 rounded-circle" [src]="image_base_url+user.image" alt="">
            </div>
            <div class="text-center">
              <p class="tx-16 fw-bolder">{{ user.fname }} {{ user.lname }}</p>
              <p class="tx-12 text-muted">{{ user.email }}</p>
            </div>
          </div>
          <ul class="list-unstyled p-1">
            <li class="dropdown-item py-2">
              <a href="javascript:" (click)="openEditModal()" class="d-flex text-body ms-0">
                <i class="feather icon-edit me-2 icon-md"></i>
                <span>Editer votre profil</span>
              </a>
            </li>
            <li class="dropdown-item py-2">
              <a href="javascript:" (click)="logout()" class="d-flex text-body ms-0">
                <i class="feather icon-log-out me-2 icon-md"></i>
                <span>Déconnexion</span>
              </a>
            </li>
          </ul>
        </div>
      </li>
    </ul>
  </div>
</nav>


<!-- Edit Modal -->
<div class="modal fade" id="editProfileModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="exampleModalLabel"> Editer votre profil </h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <form class="forms-sample" [formGroup]="addFormGroup" (ngSubmit)="submitEdit()">
          <div class="row">

            <div class="col-md-4">
              <img #target_edit_image (click)="clickEditImageInput()" src="assets/imgs/placeholder.jpg" class="" width="100%" alt="Img">
              <input hidden #target_edit_image_input type="file"  accept="image/*"  (change)="handleEditImage($event)" />
            </div>

            <div class="col-md-8">
              <div class="col-md-12">
                <div class="mb-3">
                    <label  class="form-label">Prénon</label>
                    <input formControlName="fname" type="text" class="form-control"  placeholder=""  >
                    <div *ngIf="submitted && fname?.invalid" >
                      <p *ngIf="fname?.errors?.['required']" class="text-danger mt-1" >Ce champ est requis </p>
                    </div>
                </div>
           </div>
           <div class="col-md-12">
              <div class="mb-3">
                  <label  class="form-label">Nom</label>
                  <input formControlName="lname" type="text" class="form-control"  placeholder=""  >
                  <div *ngIf="submitted && lname?.invalid" >
                    <p *ngIf="lname?.errors?.['required']" class="text-danger mt-1" >Ce champ est requis </p>
                  </div>
              </div>
           </div>

           <div class="col-md-12">
              <div class="mb-3">
                  <label  class="form-label">Téléphone</label>
                  <input formControlName="phone" type="text" class="form-control"  placeholder=""  >
                  <div *ngIf="submitted && phone?.invalid" >
                    <p *ngIf="phone?.errors?.['required']" class="text-danger mt-1" >Ce champ est requis </p>
                  </div>
              </div>
            </div>
          <div class="col-md-12">
              <div class="mb-3">
                  <label  class="form-label">Email</label>
                  <input formControlName="email" type="text" class="form-control"  placeholder=""  >
                  <div *ngIf="submitted && email?.invalid" >
                    <p *ngIf="email?.errors?.['required']" class="text-danger mt-1" >Ce champ est requis </p>
                  </div>
              </div>
          </div>

          <div class="col-md-12" style="float: right ;">
            <button type="button" class="btn btn-xs btn-primary w-100" (click)="openPasswordModal()"> <i class="feather icon-lock"></i> changer le mot de passe </button>
          </div>
            </div>
            
          </div>
      
      </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"> Annuler </button>
        <button type="button" class="btn btn-primary" (click)="submitEdit()"> <i class="feather icon-save"></i> Enregistrer </button>
      </div>
    </div>
  </div>
</div>

<!-- change password Modal -->

<div class="modal fade" id="passwordModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="exampleModalLabel"> Changer le mot de passe </h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        
        <div class="row">
             
          <div class="mb-3">
            <label  class="form-label">Mot de passe actuel </label>
            <input [(ngModel)]="old_pass_word" type="password" class="form-control"  placeholder=""  >
         </div>

          <div class="mb-3">
            <label  class="form-label">Nouveau mot de passe</label>
            <input [(ngModel)]="pass_word" type="password" class="form-control"  placeholder=""  >
         </div>

        </div>
     
       
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"> Annuler </button>
        <button type="button" class="btn btn-primary" (click)="changePassword()"> <i class="feather icon-save"></i> Enregistrer </button>
      </div>
    </div>
  </div>
</div>
  