

export const environment = {
      production: false,
      admin_base_url : "https://marketplace.api.ajicreative.club/admin/",
      api_base_url : "https://marketplace.api.ajicreative.club/api/",
      base_url: "https://marketplace.api.ajicreative.club/",
      image_base_url : "https://marketplace.api.ajicreative.club/upload/media/" 

};

