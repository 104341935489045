import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { finalize, from, Observable } from 'rxjs';
//import { AuthentificationService } from '../../authentification/services/authentification.service';
 


@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    constructor() {}
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

       let access_token = localStorage.getItem("access_token");
 
        const clonedRequest = request.clone({ headers: request.headers.set('Authorization', 'bearer ' + access_token) });
        return next.handle(clonedRequest).pipe(finalize(() => { 
                                                                 /* this.loadingService.stopLoader() */ 
                                                                } ));
      }

     
}

