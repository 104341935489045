import { NgModule, inject } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BaseComponent as AdminBaseComponent } from './pages/admin/layout/base/base.component';
import { BaseComponent as StoreBaseComponent } from './pages/boutique/layout/base/base.component';
import { ErrorPageComponent } from './pages/error-page/error-page.component';
import { PermissionService } from './services/permission.service';

export const canActivateAdmin = ( permissionService = inject(PermissionService)) => { 
  return permissionService.isAdmin() ;
}

export const canActivateStore = ( permissionService = inject(PermissionService)) => { 
  return permissionService.isStore() ;
}

const routes: Routes = [
  { 
    path:'auth', 
    loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthModule) 
  },
  {
    path: '',
    redirectTo: 'admin', pathMatch: 'full',
  },
  {   path: 'admin',
      component: AdminBaseComponent,
      canActivate: [() => canActivateAdmin()],
      children: [
        {
            path: '',
            loadChildren: () => import('./pages/admin/admin.module').then(m => m.AdminModule)
        },
    ]
  },
  {   path: 'boutique',
      component: StoreBaseComponent,
      canActivate: [() => canActivateStore()],
      children: [
        {
            path: '',
            loadChildren: () => import('./pages/boutique/boutique.module').then(m => m.BoutiqueModule)
        },
    ]
  },


  { 
    path: 'error',
    component: ErrorPageComponent,
    data: {
      'type': 404,
      'title': 'Page Not Found',
      'desc': 'Oopps!! The page you were looking for doesn\'t exist.'
    }
  },
  
  {
    path: 'error/:type',
    component: ErrorPageComponent
  },
  { path: '**', redirectTo: 'error', pathMatch: 'full' }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: true , scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
