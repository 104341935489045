import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class UserService {

  readonly adminBaseUrl: string = environment.admin_base_url;
  readonly apiBaseUrl: string = environment.api_base_url;

  constructor(
    private http: HttpClient,
  ) { }

  /* ------------------------------------------------------------------------ */

      async getUserRole() {
        const uri = this.apiBaseUrl + 'get-role';
        return this.http.post(uri, {}, { responseType: 'json' }).pipe(map(res => {
          return res;
        }));
      }
      
  /* ------------------------------------------------------------------------ */

  login(data: any) {

    const uri = this.apiBaseUrl + 'login';

    return this.http.post(uri, data, { responseType: 'json' }).pipe(map(res => {
      return res;
    }));
  }

  /* -------------------------------------------------------------------------- */


  public resetPasswod(email: any) {

    const uri = this.apiBaseUrl + 'password/reset';

    return this.http.post(uri, email, { responseType: 'json' }).pipe(map(res => {
      return res;
    }));

  }


  /* -------------------------------------------------------------------------- */


  logout() {

    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('username');

  }


  /* -------------------------------------- Get Info Admin -------------------------------------- */

  getMyInfos() {

    const uri = this.adminBaseUrl + 'me';

    return this.http.get(uri, { responseType: 'json' }).pipe(map(res => {
      return res;
    }));

  }


  /* -------------------------------------- Update Info Admin -------------------------------------- */

  updateMyInfos(data: any) {

    const uri = this.adminBaseUrl + 'me';

    return this.http.put(uri, data, { responseType: 'json' }).pipe(map(res => {
      return res;
    }));

  }

  /* -------------------------------------- Update Password Admin -------------------------------------- */

    changePassword(data: any){
        const uri = this.apiBaseUrl + 'profile/password';
        return this.http.post(uri, data, { responseType: 'json' }).pipe(map(res => {
          return res;
        }));
    }


/* -------------------------------------- Change User password -------------------------------------- */

    changeUserPassword(data: any) {
        const uri = this.adminBaseUrl + 'user/password';
        return this.http.post(uri, data, { responseType: 'json' }).pipe(map(res => {
          return res;
        }));
    }



}
