import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
 

import { AppComponent } from './app.component';
import { ErrorPageComponent } from './pages/error-page/error-page.component';

import { HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { TokenInterceptor } from './helpers/token.interceptor';



/* -----------------  NGRX  STATE MANAGEMENT ---------------- */


  import { ProfileState } from './store/profile.state';
  import  { reducer as profileReducer } from './store/profile.reducer';
  import { localStorageSync } from 'ngrx-store-localstorage';

  import { ActionReducer, ActionReducerMap, MetaReducer, StoreModule } from '@ngrx/store';
   import { PROFILE } from './store/profile.selector';


    export interface State {
        global: ProfileState;
    }

    function localStorageSyncReducer(reducer: ActionReducer<State>): ActionReducer<State> {
        return localStorageSync({
          keys: ['global'],
          rehydrate: true
        })(reducer);
    }

    const reducers: ActionReducerMap<State> = {
       global: profileReducer,
    };

    const metaReducers: Array<MetaReducer<State, any>> = [localStorageSyncReducer];

 
 /* ---------------------------------------------------------------------------------- */



@NgModule({
  declarations: [
    AppComponent,
    ErrorPageComponent,
  ],
  imports: [ 
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,

    // STATE MANAGEMENT
    StoreModule.forRoot(reducers,{metaReducers}),
    StoreModule.forFeature(PROFILE,profileReducer),
  ],

  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    {
      
      provide: HIGHLIGHT_OPTIONS, // https://www.npmjs.com/package/ngx-highlightjs
      useValue: {
        coreLibraryLoader: () => import('highlight.js/lib/core'),
        languages: {
          xml: () => import('highlight.js/lib/languages/xml'),
          typescript: () => import('highlight.js/lib/languages/typescript'),
          scss: () => import('highlight.js/lib/languages/scss'),
        }
      }
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
